<template>
  <b-modal
    id="modal-create-a-task"
    cancel-variant="outline-secondary"
    ok-title="Confirmar"
    cancel-title="Cancelar"
    centered
    title="Crear Tarea"
    size="lg"
    @ok="$emit('createTask', task)"
    :ok-disabled="task[1].status == 'completada'"
  >  
    <div class="flex gap-6">
      <div class="w-7/12">
        <p class="mb-0">
          <b>Creador: </b>{{ task[1].creator }}
        </p>
        <p><b>Fecha de creación: </b>{{ task[1].dateCreated }}</p>
        <b-form>
          <b-form-group
            label="Titulo de la tarea"
            label-for="taskName"
          >
            <b-form-input
              v-model="task[1].taskName"
              name="taskName"
            />
          </b-form-group>

          <b-form-group>
            <h5 class="text-sm font-normal">
              Fecha Resolución
            </h5>
            <flat-pickr
              v-model="task[1].date"
              class="form-control"
              :config="{ enableTime: true, dateFormat: 'Y-m-d H:i' }"
            />
          </b-form-group>
          <b-form-group
            label="Tags"
            label-for="taskTags"
          >
            <v-select
              v-model="task[1].tags"
              multiple
              label="title"
              name="taskTags"
              :options="option"
              :item-disabled ="task[1].status == 'completada'"
            />
          </b-form-group>
          <b-form-group
            label="Responsable"
            label-for="responsable"
          >
            <b-form-select v-model="task[1].assignedTo" :options="usersOption" :disabled="task[1].status == 'completada'"></b-form-select>
          </b-form-group>
          <quill-editor
            v-model="task[1].description"
            theme="snow"            
          >{{
            task[1].description
          }}</quill-editor>
        </b-form>
      </div>
      <div class="w-5/12 rounded relative bitacora">
            <div id="chatBox" class="chatBox">
              <template v-for="(item, key, index) in chatMessages">
                <div :key="key">
                  <div v-if="!item.sender" class="note">{{item.text}}</div>
                  <div v-if="item.sender == theActualUser" class="chat">                  
                    <div class="message">{{item.text}}</div>
                  </div>                
                  <div v-if="item.sender && item.sender != theActualUser" class="chatResponse">
                    <div class="avatar" v-if="chatMessages[chatKeys[index]].sender != chatMessages[chatKeys[index-1]].sender">
                      <b-avatar size="md" variant="primary" class="mr-0" :src="usersList[item.sender].picture"/>
                    </div>
                    <div class="message" v-if="chatMessages[chatKeys[index]].sender != chatMessages[chatKeys[index-1]].sender">
                      {{item.text}}
                    </div>
                    <div class="message chatContinue" v-if="chatMessages[chatKeys[index]].sender == chatMessages[chatKeys[index-1]].sender">
                      {{item.text}}
                    </div>                
                  </div>                
                </div>
              </template>                                       
            </div>
            <div v-if="task[0]" class="absolute bottom-0 flex p-1 gap-4 w-full ">
              <div class="w-10/12">
                <b-form-input
              v-model="messageToBTC"
              name="messageToBTC"
              placeholder="Mensaje"
              autocomplete="off" 
              @keyup.enter="sendMessage"             
            />
              </div>
            <div class="w-2/12"><b-button
              variant="success"
              class="btn-icon"              
              @click="sendMessage"
            >
              <feather-icon icon="SendIcon" />
            </b-button></div>
            </div>
      </div>
    </div>
  </b-modal>
</template>

<script>
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
import {
  BModal,
  BForm,
  BFormGroup,
  BFormTextarea,
  BFormInput,
  BButton,
  BAvatar,
  BFormSelect
} from 'bootstrap-vue'
import flatPickr from 'vue-flatpickr-component'
import { quillEditor } from 'vue-quill-editor'
import vSelect from 'vue-select'
import firebase from 'firebase/app'
import 'firebase/database'
import 'firebase/auth'

export default {
  components: {
    BModal,
    BForm,
    BFormGroup,
    BFormTextarea,
    flatPickr,
    quillEditor,
    BFormInput,
    vSelect,
    BButton,
    BAvatar,
    BFormSelect
  },
  props: {
    task: {
      type: Array,
      default: () => [],
    },    
    isEdit: Boolean,
  },  
  data() {
    return {
      messageToBTC:'',
      option: [
        'Programacion',
        'Twilio',
        'HMI',
        'PLC',
        'Grafana',
        'Servidor',
        'Gateway',
        'Influx',
        'Tags',
      ],
      theActualUser: '',
      chatMessages: {},
      chatKeys: [],
      usersList: null,
      usersOption: []
    }
  },  
  watch: {
    task: function (){
      this.theActualUser = firebase.auth().currentUser.uid
      firebase.database().ref('tasks/'+this.task[0]+'/bitacora').on('value', (snap)=>{
        this.chatMessages = []
        this.chatKeys = []
        if(snap.val()){
          this.chatMessages = snap.val()
          this.chatKeys = Object.keys(snap.val())
        }
        this.modalOpened()
      })               
    }
  },  
  mounted(){   
    this.usersOption = [] 
    firebase.database().ref('users').once('value', (snap)=>{
      this.usersList = snap.val()
      Object.keys(this.usersList).forEach((k,i)=>{
        this.usersOption.push({value:k, text: this.usersList[k].displayName})
      })      
    })
  },
  methods:{    
    sendMessage(){
      if(this.task[0] && this.messageToBTC){        
        const message = {
          text: this.messageToBTC,
          sender: this.theActualUser
        }
        firebase.database().ref('tasks/' + this.task[0] + '/bitacora').push(message)        
        this.messageToBTC = ''
      }
    },
    modalOpened(){      
      setTimeout(() => {
        document.querySelector('#chatBox').scrollTop = document.querySelector('#chatBox').scrollHeight
      }, 100);
    }
  }
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
@import '@core/scss/vue/libs/vue-select.scss';
</style>
