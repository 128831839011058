<template>
    <section>
        <div class="gap-4 flex overflow-x-auto">
            <div class="tasksStatus">
                <b-card class="overflow-hidden allTasks" body-bg-variant="primary">
                    <b-card-title class="text-white">
                        Nuevas Tareas
                        <b-button @click="newTask" variant="dark" style="padding:6px 12px;float:right;border:none;font-size:12px">CREAR</b-button>
                    </b-card-title>
                    <draggable
                        :list="allTasks"
                        tag="ul"
                        :group="{ name: 'automatTasks', put: false }"
                        class="list-group list-group-flush cursor-move"
                        @change="changeDrag($event, 'nueva')"
                        >
                        <b-list-group-item
                            v-for="(listItem, index) in allTasks"
                            :key="index"
                            tag="li"
                            class="p-0 bg-transparent"
                        >
                        <task :taskData="listItem" @edit="editTask" :userInfo="getDataUser(listItem[1].assignedTo)"/>
                        </b-list-group-item>
                    </draggable>
                </b-card>                
            </div>
            <div class="tasksStatus">
                <b-card class="overflow-hidden allTasks" body-bg-variant="warning">
                    <b-card-title class="text-black">
                        Trabajando
                    </b-card-title>
                    <draggable
                        :list="jobTasks"
                        tag="ul"
                        group="automatTasks"
                        class="list-group list-group-flush cursor-move"
                        @change="changeDrag($event, 'trabajando')"
                        >
                        <b-list-group-item
                            v-for="(listItem, index) in jobTasks"
                            :key="index"
                            tag="li"
                            class="p-0 bg-transparent"
                        >                        
                        <task :taskData="listItem" @edit="editTask" :userInfo="getDataUser(listItem[1].assignedTo)" />
                        </b-list-group-item>
                    </draggable>
                </b-card>
            </div>
            <div class="tasksStatus">
                <b-card class="overflow-hidden allTasks" body-bg-variant="secondary">
                    <b-card-title class="text-white">
                        En espera
                    </b-card-title>
                    <draggable
                        :list="standbyTasks"
                        tag="ul"
                        group="automatTasks"
                        class="list-group list-group-flush cursor-move"
                        @change="changeDrag($event, 'espera')"
                        >
                        <b-list-group-item
                            v-for="(listItem, index) in standbyTasks"
                            :key="index"
                            tag="li"
                            class="p-0 bg-transparent"
                        >
                        <task :taskData="listItem" @edit="editTask" :userInfo="getDataUser(listItem[1].assignedTo)" />
                        </b-list-group-item>
                    </draggable>
                </b-card>
            </div>
            <div class="tasksStatus">
                <b-card class="overflow-hidden allTasks" body-bg-variant="info">
                    <b-card-title class="text-black">
                        En revisión
                    </b-card-title>
                    <draggable
                        :list="reviewTasks"
                        tag="ul"
                        group="automatTasks"
                        class="list-group list-group-flush cursor-move"
                        @change="changeDrag($event, 'revisando')"
                        >
                        <b-list-group-item
                            v-for="(listItem, index) in reviewTasks"
                            :key="index"
                            tag="li"
                            class="p-0 bg-transparent"
                        >
                        <task :taskData="listItem" @edit="editTask" :userInfo="getDataUser(listItem[1].assignedTo)" />
                        </b-list-group-item>
                    </draggable>
                </b-card>
            </div>
            <div class="tasksStatus">
                <b-card class="overflow-hidden allTasks" body-bg-variant="success">
                    <b-card-title class="text-black">
                        Completadas
                        <b-button @click="propia = !propia" variant="dark" style="padding:6px 12px;float:right;border:none;font-size:12px">Propias</b-button>
                    </b-card-title>
                    <draggable
                        :list="completedTasks"
                        tag="ul"
                        :group="{ name: 'automatTasks', pull: false }"
                        class="list-group list-group-flush cursor-move"
                        @change="changeDrag($event, 'completada')"
                        >
                        <b-list-group-item
                            v-for="(listItem, index) in completedTasks"
                            :key="index"
                            tag="li"
                            class="p-0 bg-transparent"
                        >                        
                        <task v-if="!propia || userActual == listItem[1].assignedTo" :taskData="listItem" @edit="editTask" :userInfo="getDataUser(listItem[1].assignedTo)" />
                        </b-list-group-item>
                    </draggable>
                </b-card>
            </div>
        </div>
        <create-task :task="editing" @createTask="addTask" />         
    </section>
</template>

<script>
import {BCard, BRow, BCol, BListGroupItem, BAvatar, BCardText, BCardTitle, BButton} from 'bootstrap-vue'
import draggable from 'vuedraggable'
import task from './Components/Task.vue'
import createTask from './Components/CreateTask.vue'
import firebase from 'firebase/app'
import 'firebase/database'
import 'firebase/auth'

export default {
    components:{
        BCard, BCol, BRow, draggable, BListGroupItem, BAvatar,BCardText, BCardTitle, task, createTask, BButton
    },
    data (){
        return{
            allTasks: [],
            jobTasks: [],
            standbyTasks: [],
            reviewTasks: [],
            completedTasks:[],
            editing: ['',{
                name: '',
                email: '',
                tags: [],
                taskName: '',
                status: 'nueva',
                date: '',
                description: '',
                creator:'',
                dateCreated: ''
            }],
            userActual: '',
            usersList: null,
            propia: false
        }
    },
    async mounted(){
        this.userActual = firebase.auth().currentUser.uid
        await firebase.database().ref('users').once('value').then((snap)=>{
            this.usersList = snap.val()            
        })
        firebase.database().ref('tasks').on('value', (snapshot)=>{
            
            this.allTasks = []
            this.jobTasks = []
            this.standbyTasks = []
            this.reviewTasks = []
            this.completedTasks = []
            const elements = snapshot.val()            
            Object.keys(elements).forEach(element => {                
                const status = elements[element].status
                if(status == 'nueva') this.allTasks.push([element, elements[element]])                
                if(status == 'trabajando') this.jobTasks.push([element, elements[element]])
                if(status == 'espera') this.standbyTasks.push([element, elements[element]])
                if(status == 'revisando') this.reviewTasks.push([element, elements[element]])
                if(status == 'completada') this.completedTasks.push([element, elements[element]])
            });
        })        
    },
    methods:{        
        changeDrag(event, na){
            if(event.added){                
                if(event.added.element[1].status == 'nueva') firebase.database().ref('tasks/'+ event.added.element[0]).child('assignedTo').set(firebase.auth().currentUser.uid)                
                firebase.database().ref('tasks/'+ event.added.element[0]).child('status').set(na)
                firebase.database().ref('tasks/' + event.added.element[0] + '/bitacora').push({text: 'Tarea movida a ' + na, do: this.userActual})
            }            
        },
        newTask(){
            this.resetObject()
            const hoy = new Date()
            this.editing[1].creator = firebase.auth().currentUser.email
            this.editing[1].dateCreated = hoy.toLocaleString()
            this.$bvModal.show('modal-create-a-task')
        },
         editTask(event){
             this.editing = event
             this.$bvModal.show('modal-create-a-task')             
         },
        resetObject(){
            this.editing = ['',{
                name: '',
                email: '',
                tags: [],
                taskName: '',
                status: 'nueva',
                date: '',
                description: '',
                creator:'',
                dateCreated: ''
            }]
        },
        getDataUser(element){
            if(this.usersList[element]) return this.usersList[element]
            else return {}            
        },
        addTask(event){
            const key = event[0] 
            if(key){
                const contentToUpdate = {
                    taskName: event[1].taskName,
                    date: event[1].date,                    
                    description: event[1].description,
                    assignedTo: event[1].assignedTo
                }
                if(event[1].tags) contentToUpdate.tags = event[1].tags

                firebase.database().ref('tasks/'+key).update(contentToUpdate, ()=>{
                    firebase.database().ref('tasks/' + key + '/bitacora').push({text: 'Tarea actualizada', do:this.userActual})
                    this.$bvToast.toast('Tarea Actualizada Correctamente', {
                        title: 'Correcto...',
                        variant: 'info',
                        solid: true,
                    })
                })
            }else{
                const newTask = firebase.database().ref('tasks').push(event[1], ()=>{
                    this.$bvToast.toast('Tarea Creada Correctamente', {
                        title: 'Correcto...',
                        variant: 'success',
                        solid: true,
                    })
                })                
                firebase.database().ref('tasks/' + newTask.key + '/bitacora').push({text: 'Tarea creada', do: this.userActual})
            }                      
        }
    }
}
</script>
<style scoped>
.tasksStatus{
    min-width: 335px!important;
    height: calc(100vh - 250px);
    overflow: auto;
    margin-bottom: 2em;
}
.allTasks{
    margin: 0;
}
</style>