<template>
    <b-card class="mb-1" v-if="taskData" :title="taskData[1].taskName" @dblclick="editTask">
        <b-card-sub-title class="mb-1">              
            <b-badge pill variant="light-primary" style="margin-right: 0.5em;" v-for="(tag,i) in taskData[1].tags" :key="i">{{tag}}</b-badge>
        </b-card-sub-title>                  
        <footer>
            <div class="d-flex">
                <b-avatar v-if="userInfo.picture" :src="userInfo.picture"  variant="primary" />
                <b-avatar v-if="!userInfo.picture && userInfo.displayName" :text="userInfo.displayName.slice(0,2)"  variant="primary" />
                <b-avatar v-if="!userInfo.displayName && !userInfo.picture" variant="primary" />
                <div class="ml-25">
                    <b-card-text class="mb-0 font-weight-bold" v-if="userInfo.displayName">
                        {{ userInfo.displayName }}
                    </b-card-text>
                    <template v-if="taskData[1].status != 'completada'">
                        <small v-if="rangeDate() > 0 && rangeDate() != 1">Entrega en {{ rangeDate() }} dia(s)</small>
                        <small v-if="rangeDate() < 0" class="text-red-800">Entrega atrasada | {{ Math.abs(rangeDate()) }} dia(s)</small>
                        <small v-if="rangeDate() == 1" class="text-red-600">Entrega hoy</small>
                        <small v-if="!rangeDate()">Fecha de entrega no disponible</small>
                    </template>
                    <template v-if="taskData[1].status == 'completada'">
                        <small>Completada</small>
                    </template>
                </div>
            </div>
        </footer>
    </b-card>
</template>

<script>
import {BCard, BCardText, BCardTitle, BCardSubTitle, BAvatar, BBadge } from 'bootstrap-vue'

export default {
    components:{
        BCard, BCardText, BCardTitle, BCardSubTitle, BAvatar, BBadge 
    },
    props:{
        taskData:{
            type:Array,
            default: () => []
        },
        userInfo:{
            type:Object,
            default: () => {}
        },
    },
    methods: {
        rangeDate(){
            const fechaEntrega = new Date(this.taskData[1].date).getTime()
            const fechaHoy = new Date().getTime()
            const diffTime = fechaEntrega - fechaHoy;
            const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));             
            return diffDays 
        },
        editTask(){            
            this.$emit('edit', this.taskData)
        },        
    },
}
</script>